import { debounce } from "debounce"
import onClickAddFieldBtn from "./onClickAddFieldBtn"
import onClickRemoveFieldBtn from "./onClickRemoveFieldBtn"
import videoInputType from "./videoInputType"
import "styles/pages/project.scss"

const steppedForms = {}

const onChangeCreateHiddenSelect = el => {
  const tpl = el.parentNode.querySelector(`#${el.dataset.tplid}`).innerHTML
  const holderEl = el.parentNode.querySelector(".dynamic-fields")

  let fieldNodes = ""
  if (el.multiple) {
    ;[...el.options]
      .map(({ selected, value }) => (selected ? value : null))
      .filter(v => v)
      .forEach((value, index) => {
        fieldNodes += tpl
          .replace(/\{newFieldId\}/g, `new${index}`)
          .replace(/\{value\}/g, value)
      })
  } else {
    fieldNodes = tpl
      .replace(/\{newFieldId\}/g, `new1`)
      .replace(/\{value\}/g, el.options[el.selectedIndex].value)
  }

  holderEl.innerHTML = fieldNodes
}

const onChangeMultipleSelect = el => {
  const helpHolder = document.getElementById(`${el.id}Help`)
  if (helpHolder) {
    helpHolder.innerHTML = [...el.options]
      .map(({ selected, text }) => (selected ? text : null))
      .filter(text => text)
      .map(text => `<span class="badge badge-pill badge-dark">${text}</span>`)
      .join(" ")
  }
}

const onToggleCheckboxCreateHiddenSelect = ({ el, options, fieldgroupid }) => {
  const tplId = el.dataset.tplid.replace("tpl--", "")
  let index = 1
  let newId = ""
  const tpl = document.getElementById(`tpl--${tplId}`).innerHTML
  const holderEl = document.getElementById(`dynamic-fields-${fieldgroupid}`)

  let fieldNodes = ""
  ;[...options]
    .map(({ checked, value, dataset }) => ({
      checked,
      value,
      blockId: dataset.blockid
    }))
    .filter(({ checked }) => checked)
    .forEach(({ value, blockId }) => {
      if (blockId !== undefined) {
        newId = blockId
      } else {
        ++index
        newId = `new${index}`
      }

      fieldNodes += tpl
        .replace(/\{newFieldId\}/g, newId)
        .replace(/\{value\}/g, value)
    })

  holderEl.innerHTML = fieldNodes
}

const onChangeLinkedInputFieldSelect = ({ el, formEl }) => {
  formEl[el.dataset.nameref].value = el.options[el.selectedIndex].value
}

const initFileInput = ({ el, formEl }) => {
  el.addEventListener("change", () => {
    formEl.querySelector(`#${el.id}Help`).innerHTML = [...el.files]
      .map(({ name }) => name)
      .join(" ")

    const imgPreview = formEl.querySelector(`#${el.id}Preview`)
    if (imgPreview) {
      if (el.files[0]) {
        const reader = new FileReader()
        reader.onload = event => (imgPreview.src = event.target.result)
        reader.readAsDataURL(el.files[0])
      } else {
        imgPreview.src = imgPreview.dataset.defaultsrc
      }
    }
  })
}

const transformDateToBackendFormat = date => {
  const year = date.getFullYear()

  let month = (1 + date.getMonth()).toString()
  month = month.length > 1 ? month : "0" + month

  let day = date.getDate().toString()
  day = day.length > 1 ? day : "0" + day

  return `${year}-${month}-${day}`
}

const onFormContentChanged = formEl => {
  formEl.classList.add("form--unsaved")
  formEl.classList.remove("form--saved")
}

const ProjectForm = formEl => {
  const selectEls = [...formEl.querySelectorAll("select")].map(el => {
    if (el.dataset.tplid) {
      el.onchange = () => onChangeCreateHiddenSelect(el)
    }

    if (el.multiple) {
      el.onchange = () => onChangeMultipleSelect(el)
      onChangeMultipleSelect(el)
    }
  })

  $(formEl).on("change", ".linkedInputFieldSelect", event =>
    onChangeLinkedInputFieldSelect({ el: event.target, formEl })
  )

  // Project event registration is a special case that use a custom plugin action
  // to save the value: /actions/members-functions/register
  const eventRegistrationEl = document.getElementById("eventOrIncubator")
  if (eventRegistrationEl) {
    eventRegistrationEl.onchange = () => {
      const { projectid } = event.currentTarget.dataset
      const { csrfTokenName, csrfTokenValue, locale } = __PAGE_VARS__
      const listEl = eventRegistrationEl.parentNode

      listEl.classList.add("busy")

      $.post({
        url: "/actions/members-functions/register",
        data: {
          [csrfTokenName]: csrfTokenValue,
          eventid:
            eventRegistrationEl.options[eventRegistrationEl.selectedIndex]
              .value,
          projectid,
          locale
        },
        dataType: "json"
      }).always((response, textStatus) => {
        listEl.classList.remove("busy")
        const { eventId } = response
        if (eventId && textStatus !== "error") {
          ;[...eventRegistrationEl.options].forEach(
            option => (option.selected = option.value == eventId)
          )
        } else {
          // TODO: notify error to user
          listEl.classList.add("error")
          console.log(response.responseJSON)
        }
      })
    }
  }

  const checkboxesGroup = [...formEl.querySelectorAll(".checkboxesGroup")].map(
    el => {
      $(el).on("change", 'input[type="checkbox"]', event => {
        const options = el.querySelectorAll('input[type="checkbox"]')
        onToggleCheckboxCreateHiddenSelect({
          el: event.target,
          options,
          fieldgroupid: el.dataset.fieldgroupid
        })
      })
    }
  )

  const addButtonEls = [...formEl.querySelectorAll("button[data-tplid]")].map(
    el => {
      $(el).on("click", event => onClickAddFieldBtn({ event, el }))
    }
  )
  $(formEl).on("click", ".btn--removeField", onClickRemoveFieldBtn)

  $(formEl).on("click", ".btn--upload", event => {
    event.preventDefault()
    $(formEl[event.target.dataset.nameref]).trigger("click")
  })
  const fileInputList = [...formEl.querySelectorAll('input[type="file"]')].map(
    el => initFileInput({ el, formEl })
  )

  const videoInputList = [
    ...formEl.querySelectorAll(".videoEmbedPreview__urlInput")
  ].map(videoInputType)

  // Once the form is ready, display it
  formEl.parentNode.style.cssText = ""

  // If the form is a step by step, initialize it
  // TODO: extract in separate component
  if (formEl.classList.contains("form--stepped")) {
    const formId = formEl.id || "form"
    const stepElList = [...formEl.querySelectorAll(".form--stepped__step")]
    const navItems = stepElList.map((el, i) => {
      const id = `${formId}[${i}]`
      el.id = id
      return `<button type="button" data-step="${id}" class="form--stepped__navItem form--stepped__navItem-dot">${i +
        1}</button>`
    })

    stepElList.forEach(stepEl => {
      const stepIndex = /(?<=\[)(\d*)(?=\])/.exec(stepEl.id)[0]
      stepEl.querySelectorAll('[required]').forEach(e => e.dataset.step = stepIndex)
    })

    const stepsEl = formEl.querySelector(".form--stepped__steps")
    stepsEl.scrollLeft = 0

    steppedForms[formId] = {
      id: formId,
      current: 0,
      max: navItems.length - 1,
      el: stepsEl,
      stepElList,
      onChanged: stepForm => onSteppedFormChanged(stepForm)
    }

    const formFooter = formEl.querySelector(".form__footer")
    const arrowNav = `<div class="container form--stepped__directionalNav"><div class="row no-scrollreveal visible"><div class="col-md-12 col-lg-8 col-xl-7 m-auto d-flex justify-content-between"><button type="button" class="form--stepped__navItem icon_arrowPrev form--stepped__nav-prev" data-step="${formId}[-1]">Retour</button><button type="button" class="btn btn--sm btn--primary form--stepped__navItem form--stepped__nav-next" data-step="${formId}[+1]">Suivant</button></div></div></div>`
    stepsEl.classList.add(`${formId}__steps`)
    formFooter.insertAdjacentHTML("beforebegin", `${arrowNav}`)
    formFooter.insertAdjacentHTML(
      "afterend",
      `<div class="form--stepped__nav">${navItems.join("")}</div>`
    )

    steppedForms[formId].dotElList = [
      ...formEl.querySelectorAll(".form--stepped__navItem-dot")
    ]

    $(formEl).on("click", ".form--stepped__navItem", event => {
      event.preventDefault()
      const parts = event.currentTarget.dataset.step
        .replace(/[#\]]/, "")
        .split("[")
      const stepped = steppedForms[parts[0]]
      const { current, max } = stepped
      const direction = parts[1]

      let stepIndex = 0
      switch (direction) {
        case "+1":
          stepIndex = Math.min(current + 1, max)
          break
        case "-1":
          stepIndex = Math.max(current - 1, 0)
          break
        default:
          stepIndex = parseInt(direction, 10)
      }

      stepped.current = stepIndex

      onSteppedFormChanged(stepped)
    })

    // Resize dynamically when elements are added or removed
    $(formEl).on("click", event => {
      const stepped = steppedForms[event.currentTarget.id]
      if (stepped) {
        // Move to first empty required field
        const requiredFieldList = [...event.currentTarget.querySelectorAll('[required]:invalid')]
        if (event.target.type === 'submit' && requiredFieldList.length > 0) {
          stepped.current = parseInt(requiredFieldList[0].dataset.step, 10) || 0
        }

        onSteppedFormChanged(stepped)
      }
    })

    import("styles/components/forms/steppedForm").then(() => {
      onSteppedFormChanged(steppedForms[formId])
    })
  }

  const onSteppedFormChanged = debounce(stepped => { console.log('onSteppedFormChanged', stepped)
    const stepIndex = stepped.current
    const stepsEl = stepped.el

    stepped.dotElList.forEach((el, index) => {
      if (index === stepIndex) {
        el.classList.add("form--stepped__navItem-dot--active")
      } else {
        el.classList.remove("form--stepped__navItem-dot--active")
      }
    }, 400)

    // Resize holder...
    const currentStepEl = stepped.stepElList.filter(
      ({ id }) => id === `addProjectForm_new[${stepIndex}]`
    )[0]
    if (currentStepEl) {
      stepsEl.style.height = currentStepEl.scrollHeight + "px"
    } else {
      stepsEl.style.height = "auto"
    }
    // and move...
    stepsEl.scrollLeft = stepIndex * stepsEl.offsetWidth
  })

  if (formEl.dataset.warnbeforequit) {
    $(formEl).on("change paste", event => {
      onFormContentChanged(formEl)
    })
    $(formEl).on(
      "keyup",
      debounce(event => {
        onFormContentChanged(formEl)
      }, 200)
    )

    // before leave, check if some fields are unsaved
    window.addEventListener("beforeunload", event => {
      if (formEl.classList.contains("form--unsaved")) {
        console.log("Unsaved form", formEl)
        // Cancel the event
        event.preventDefault()
        // Chrome requires returnValue to be set
        event.returnValue = ""
      }
    })
  }

  return steppedForms
}

export { ProjectForm }
