import $ from "jquery"
import Flickity from "flickity"
import Typed from "typed.js"
import ScrollReveal from "scrollreveal"

import loadComponents from "./components/loadComponents"

import "styles/index.scss"
import "styles/critical.scss"
import "styles/global.scss"
import "styles/mobile/index.scss"
// HACK: use import
require("flickity-as-nav-for")

// Needed for old-school jQuery plugins
window.jQuery = $
window.$ = $

// Based on craft page type we load the optional associated page module
const { jsModuleName } = __PAGE_VARS__

// Ensure the body is not scrolled, to display the loader
if (!IS_DEV) {
  window.scrollTo(0, 0)
}

const body = document.body
const scrollUp = "scroll-up"
const scrollDown = "scroll-down"
let lastScroll = 0

// BUG: scroll-down are not removed and top nav bar still hidded
window.addEventListener("scroll", () => {
  const currentScroll = window.pageYOffset
  if (currentScroll < 100) {
    body.classList.remove(scrollUp)
    return
  }

  if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
    // down
    body.classList.remove(scrollUp)
    body.classList.add(scrollDown)
  } else if (
    currentScroll < lastScroll &&
    body.classList.contains(scrollDown)
  ) {
    // up
    body.classList.remove(scrollDown)
    body.classList.add(scrollUp)
  }
  lastScroll = currentScroll
})

// Open-Close main menu on mobile
const bodyEl = document.querySelector("body")
const menuBtnEl = document.querySelector(".header__menuIcon")
const iconMenuEl = menuBtnEl.querySelector(".menuIcon-inner")
menuBtnEl.onclick = function() {
  bodyEl.classList.toggle("menu--opened")
  iconMenuEl.classList.toggle("menuIcon__close")
}

async function getPageModule({ moduleId = "default" }) {
  const validModuleId = moduleId === null ? "default" : moduleId.toString()

  let module = {
    home: () => import("./home"),
    about: () => import("./about"),
    cubanhat: () => import("./cubanhat/index"),
    members: () => import("./members/index"),
    profile: () => import("./members/profile"),
    projects: () => import("./projects"),
    project: () => import("./project"),
    events: () => import("./events"),
    event: () => import("./event"),
    createProject: () => import("./createProject")
  }[validModuleId]

  module =
    typeof module === "function"
      ? module
      : () => ({
          default: () => console.log("default module")
        })

  const loadedModule = await module()
  const moduleName =
    validModuleId.charAt(0).toUpperCase() + validModuleId.slice(1)

  return loadedModule[moduleName] || loadedModule.default
}

const refreshUserSession = () => {
  const { csrfTokenName, csrfTokenValue, userId } = __PAGE_VARS__

  if (!userId || userId === '') return

  $.post({
    url: "/actions/users/get-remaining-session-time",
    data: { [csrfTokenName]: csrfTokenValue },
    dataType: "json",
    success: (response, textStatus) => {
      console.log("session-info", response)
      const { isGuest, timeout, csrfTokenValue } = response

      if (csrfTokenValue) {
        window.__PAGE_VARS__.csrfTokenValue = csrfTokenValue
        ;[...document.getElementsByName("CRAFT_CSRF_TOKEN")].forEach(
          input => (input.value = csrfTokenValue)
        )
      }

      if (isGuest) {
        document.body.classList.add("user-is-guest")
      } else {
        document.body.classList.remove("user-is-guest")
        setTimeout(refreshUserSession, timeout * 900)
      }
    }
  }).fail(console.warn)
}

const initTyped = () => {
  const typedHeader = [...document.querySelectorAll(".content-header .typed")]
  if (typedHeader[0]) {
    typedHeader.map(
      element =>
        new Typed(element, {
          stringsElement: ".content-header .typed-strings",
          typeSpeed: 40,
          showCursor: false
        })
    )
  }

  let typedRacoon = { start: () => {}, stop: () => {} }
  if (document.querySelector("footer .typed")) {
    typedRacoon = new Typed("footer .typed", {
      stringsElement: "footer .typed-strings",
      typeSpeed: 20,
      showCursor: false,
      loop: true,
      backDelay: 1500,
      backSpeed: 0,
      smartBackspace: true
    })
    typedRacoon.stop()
  }

  document.body.classList.add("typedString--ready")

  return typedRacoon
}

getPageModule({ moduleId: jsModuleName }).then(initPageModule => {
  let loadDelaySimulation = 0

  if (IS_DEV) {
    loadDelaySimulation = 500
  }

  setTimeout(() => {
    window.mr = window.mr || {}

    window.mr = (function(mr, $, window, document) {
      mr = mr || {}

      var components = {
        documentReady: [],
        documentReadyDeferred: [],
        windowLoad: [],
        windowLoadDeferred: []
      }

      mr.status = {
        documentReadyRan: false,
        windowLoadPending: false
      }

      $(document).ready(documentReady)
      $(window).on("load", windowLoad)

      function documentReady(context) {
        context = typeof context === typeof undefined ? $ : context
        components.documentReady
          .concat(components.documentReadyDeferred)
          .forEach(function(component) {
            component(context)
          })
        mr.status.documentReadyRan = true
        if (mr.status.windowLoadPending) {
          windowLoad(mr.setContext())
        }
      }

      function windowLoad(context) {
        if (mr.status.documentReadyRan) {
          mr.status.windowLoadPending = false
          context = typeof context === "object" ? $ : context
          components.windowLoad
            .concat(components.windowLoadDeferred)
            .forEach(function(component) {
              component(context)
            })
        } else {
          mr.status.windowLoadPending = true
        }
      }

      mr.setContext = function(contextSelector) {
        var context = $
        if (typeof contextSelector !== typeof undefined) {
          return function(selector) {
            return $(contextSelector).find(selector)
          }
        }
        return context
      }

      mr.components = components
      mr.documentReady = documentReady
      mr.windowLoad = windowLoad

      return mr
    })(window.mr, $, window, document)

    const footerTyped = initTyped()

    // Scroll reveal effect is disabled on mobile
    window.sr = ScrollReveal({ reset: true })
    window.sr.reveal(".row:not(.no-scrollreveal)", {
      duration: 1000,
      scale: 0.98
    })

    window.racoonReveal = ScrollReveal({ reset: true })
    window.racoonReveal.reveal(".row.racoonReveal", {
      duration: 1000,
      scale: 0.98,
      afterReveal: () => footerTyped.start(),
      beforeReset: () => footerTyped.stop()
    })

    const footerSlidesEl = document.querySelector("footer .slider .slides")
    if (footerSlidesEl) {
      const footerSlideFlkty = new Flickity(footerSlidesEl, {
        autoPlay: true,
        groupCells: true,
        pageDots: false,
        cellAlign: "left",
        prevNextButtons: false,
        wrapAround: true
      })
    }

    initPageModule()
    refreshUserSession()

    document.body.classList.remove("preload")

    if ("sr" in window) {
      window.sr.sync()
    }

    // Redirect now if a auto redirect link is found
    const autoRedirectLink = document.querySelector(".autoRedirectLink")
    if (autoRedirectLink) {
      setTimeout(() => window.location.replace(autoRedirectLink.href), 5000)
      return
    }

    // After page module init, lazy load & init all the conditionally needed components
    loadComponents()
  }, loadDelaySimulation)
})
