import Flickity from "flickity"
import { ProjectForm } from "./forms/ProjectForm"

export default () => {
  const navEl = document.querySelector(".main-navigation")

  if (__PAGE_VARS__.voteIsEnabled) {
    import("./votes").then(({ Votes }) => Votes())
  }

  const toggleButtonElList = [
    ...document.querySelectorAll("button[data-toggleel]")
  ]
  if (toggleButtonElList.length > 0) {
    toggleButtonElList.forEach(button => {
      $(button).on("click", event => {
        const elToToggle = document.getElementById(
          event.currentTarget.dataset.toggleel
        )
        if (elToToggle) {
          elToToggle.classList.toggle(elToToggle.dataset.hiddenclass)
        }
      })
    })
  }

  const gotoAnchorElList = [...document.querySelectorAll("a.gotoAnchor-link")]
  if (gotoAnchorElList.length > 0) {
    gotoAnchorElList.forEach(el => {
      $(el).on("click", event => {
        event.preventDefault()
        const elToScrollTo = document.getElementsByName(
          event.currentTarget.href.split("#")[1]
        )[0]

        if (elToScrollTo) {
          const bbox = elToScrollTo.getBoundingClientRect()
          const scrollTop = (bbox.y || bbox.top) - navEl.offsetHeight
          var viewPortH = Math.max(
            document.documentElement.clientHeight,
            window.innerHeight || 0
          )
          $("html, body").animate({ scrollTop }, (scrollTop / viewPortH) * 600)
        }
      })
    })
  }

  // TODO: basic implementation > improve using srcset + in viewport
  const basicLazyLoadedImgs = [
    ...document.querySelectorAll("img[data-src]")
  ].map(img => {
    img.onload = () => {
      img.onload = null
      img.classList.add("img-lazy--loaded")
    }
    img.classList.add("img-lazy")
    img.src = img.dataset.src

    return img
  })

  const videoEmbedElList = [
    ...document.querySelectorAll(".videoPlayer--videoEmbed")
  ]
  if (videoEmbedElList.length > 0) {
    import("./videos/VideoEmbed").then(({ VideoEmbed }) => {
      videoEmbedElList.map(VideoEmbed)
    })
  }

  const applaudeList = [...document.querySelectorAll(".applause")]
  if (applaudeList.length > 0) {
    import("./applause").then(({ Applause }) => {
      applaudeList.map(Applause)
    })
  }

  const modalElList = [...document.querySelectorAll(".modal")]
  if (modalElList.length > 0) {
    import("./modals").then(({ Modals }) => {
      modalElList.map(Modals)
    })
  }

  const formElList = [...document.querySelectorAll(".form--ajax")]
  if (formElList.length > 0) {
    import("./forms").then(({ initForm }) => {
      formElList.map(el => initForm({ el }))
    })
  }

  const projectForm = [...document.querySelectorAll(".form--addProject")]
  if (projectForm.length > 0) {
    // HACK: needed to callback the stepped forms in src/scripts/createProject.js
    __PAGE_VARS__.projectForm = projectForm.map(ProjectForm)
  }

  const siblingScrollerEl = [
    ...document.querySelectorAll(".siblingList__scroller")
  ]
  if (siblingScrollerEl.length > 0) {
    siblingScrollerEl.map(el => {
      let initialIndex = 0
      const currentItemEl = el.querySelector(".siblingList__item--current")
      if (currentItemEl) {
        initialIndex = parseInt(currentItemEl.dataset.pos, 10)
      }
      return new Flickity(el, {
        pageDots: false,
        cellAlign: "center",
        prevNextButtons: true,
        wrapAround: true,
        bgLazyLoad: 1,
        initialIndex
      })
    })
  }
}
