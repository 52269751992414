const onClickRemoveFieldBtn = event => {
  const el = event.target.dataset.fieldid ? event.target : event.currentTarget
  const hasErrorKey = el.dataset.errorkey
  const fieldId = el.dataset.fieldid
  const fieldEl = document.querySelector(`[data-fieldId=${fieldId}]`)
  const parentEl = fieldEl.parentNode

  parentEl.removeChild(fieldEl)

  if (hasErrorKey) {
    // Errors key must be reordered in reverse order
    parentEl
      .querySelectorAll()
      .forEach(
        (el, index) =>
          (el.dataset.errorkey = el.dataset.errorkey.replace(
            /\[.\]/,
            `[${index}]`
          ))
      )
  }
}

export default onClickRemoveFieldBtn
