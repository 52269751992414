const getVideoPropsFromUrl = url => {
  let videoProps = { id: -1, type: false }
  const vimeoRegex = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
  const youtubeRegex = /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\/?\?(?:\S*?&?v\=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/gm

  if (vimeoRegex.test(url)) {
    videoProps.type = "vimeo"
    videoProps.id = vimeoRegex.exec(url)
    videoProps.id = videoProps.id[4]
  } else if (youtubeRegex.test(url)) {
    console.log("youtube", url)

    let videoId = null
    // BUG: regex need to be declared a second time in order to work properly...
    const regex = /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\/?\?(?:\S*?&?v\=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/gm
    let result
    while ((result = regex.exec(url)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (result.index === regex.lastIndex) {
        regex.lastIndex++
      }

      result.forEach(match => (videoId = match))
    }

    if (videoId) {
      videoProps.type = "youtube"
      videoProps.id = videoId
    }
  }

  return videoProps
}

const insertEmbed = ({ el, id, type }) => {
  const embedTpl = {
    vimeo:
      '<iframe src="https://player.vimeo.com/video/{videoId}?color=ffffff" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe><script src="https://player.vimeo.com/api/player.js"></script>',
    youtube:
      '<iframe src="https://www.youtube-nocookie.com/embed/{videoId}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen allow="autoplay; encrypted-media"></iframe>'
  }

  const playerEl = el.parentNode.querySelector(".videoEmbedPreview__player")
  playerEl.innerHTML = embedTpl[type].replace("{videoId}", id)
  playerEl.classList.remove("hidden")
}

const onVideoInputChanged = el => {
  if (el.value.length > 8) {
    const videoId = getVideoPropsFromUrl(el.value)
    if (videoId.type) {
      insertEmbed({ el, ...videoId })
    } else {
      clearPreview(el)
    }
  } else {
    clearPreview(el)
  }
}

const clearPreview = el => {
  el.parentNode
    .querySelector(".videoEmbedPreview__player")
    .classList.add("hidden")
}

const videoInputType = el => {
  $(el).on("change paste keyup", ({ target }) => onVideoInputChanged(target))
  onVideoInputChanged(el)

  return el
}

export default videoInputType
