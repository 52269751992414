const onClickAddFieldBtn = ({ event, el }) => {
  event.preventDefault()

  const { fieldgroupid, type, tplid } = el.dataset

  const tpl = document.getElementById(tplid).innerHTML
  const holderEl = document.getElementById(`dynamic-fields-${fieldgroupid}`)
  const fieldType = type || ""

  // IMPROVE: quick'n'dirty, but working hack...
  if (__PAGE_VARS__.newFieldsCount) {
    __PAGE_VARS__.newFieldsCount++
  } else {
    __PAGE_VARS__.newFieldsCount = 1
  }

  const index = __PAGE_VARS__.newFieldsCount

  holderEl.insertAdjacentHTML(
    "afterbegin",
    tpl
      .replace(/\{newFieldId\}/g, `new${index}`)
      .replace(/\{fieldType\}/g, fieldType)
      .replace(/\{fieldIndex\}/g, index)
  )

  // Errors key must be reordered in reverse order
  holderEl.querySelectorAll(".form-group").forEach((el, index) => {
    el.querySelectorAll("input").forEach(inputEl => {
      const { errorkey } = inputEl.dataset
      if (errorkey) {
        inputEl.dataset.errorkey = errorkey.replace(/\[.\]/, `[${index}]`)
      }
    })
  })
}

export default onClickAddFieldBtn
